<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="订单编号：">
                    <el-input size="small" placeholder="请输入订单编号" v-model="searchForm.ordersn"></el-input>
                </el-form-item>
                <el-form-item label="店铺名称：">
                    <el-input size="small" placeholder="请输入店铺名称" v-model="searchForm.store_name"></el-input>
                </el-form-item>
                <el-form-item label="付款时间：">
                    <el-date-picker size="small" v-model="searchForm.create_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="用户电话：">
                    <el-input size="small" placeholder="请输入活动名称" v-model="searchForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="支付方式：">
                    <el-select v-model="searchForm.pay_type" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="微信" :value="0"></el-option>
                        <el-option label="支付宝" :value="1"></el-option>
                        <el-option label="银联支付" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="ordersn" label="订单编号" align="center" min-width="240"></el-table-column>
                <el-table-column prop="shop_info" label="店铺名称" min-width="150" align="center">
                    <template v-slot="{ row }">{{ row.shop_info.store_name }}</template>
                </el-table-column>
                <el-table-column prop="store_name" label="支付方式" min-width="150" align="center">
                    <template v-slot="{ row }">{{ row.pay_type == 0 ? '微信' : row.pay_type == 1 ? '支付宝' : row.pay_type == 2 ?
                        '银联支付' : '--'
                    }}</template>
                </el-table-column>
                <el-table-column prop="register_mobile" label="付款人" min-width="150" align="center">
                    <template v-slot="{ row }">
                        <div>{{ row.user_info.name }}</div>
                        <div>{{ row.user_info.mobile }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="总价" min-width="150" align="center">
                    <template v-slot="{ row }">￥{{ row.money }}</template>
                </el-table-column>
                <el-table-column label="消费卡扣款金额" min-width="150" align="center">
                    <template v-slot="{ row }">-￥{{ row.user_consumer_card_deduct }}</template>
                </el-table-column>
                <el-table-column label="优惠金额" min-width="150" align="center">
                    <template v-slot="{ row }">-￥{{ row.reduction_amount }}</template>
                </el-table-column>
                <el-table-column label="支付金额" min-width="150" align="center">
                    <template v-slot="{ row }">￥{{ (row.money - row.user_consumer_card_deduct - row.reduction_amount
                    ).toFixed(2) }}</template>
                </el-table-column>
                <el-table-column label="付款时间" min-width="180" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                create_time: '',
                ordersn: '',
                store_name: '',
                pay_type: '',
                status: '',
                mobile: '',
            },
        };
    },
    created () {
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                create_time: '',
                ordersn: '',
                store_name: '',
                pay_type: '',
                status: '',
                mobile: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            if (searchForm.create_time) {
                searchForm.create_time = [searchForm.create_time[0] / 1000, searchForm.create_time[1] / 1000]
            }
            this.$axios.post(this.$api.repair.goods.PayOrderList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleVerify (id) {
            this.verify_form.id = id
            this.verify_form.status = 1
            this.dialogVisible = true
        },
        confirmVerify () {
            this.$axios.post(this.$api.repair.marketing.ActivityVerify, this.verify_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('核销成功');
                    this.dialogVisible = false
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleIntegral (id) {
            this.integral_form.id = id
            this.dialogIntegral = true
        },
        confirmIntegral () {
            this.$axios.post(this.$api.repair.marketing.ActivityAddIntegral, this.integral_form).then(res => {
                if (res.code == 0) {
                    this.$message.success('赠送成功');
                    this.dialogIntegral = false
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleReason (gift_reason) {
            this.gift_reason = gift_reason
            this.dialogReason = true
        }
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  